import React, { useContext, useEffect, useState } from 'react';
import { UserProfileContext } from '../../../context/userProfileContext';
import InputWrapper from '../../../components/inputWrapper/InputWrapper';
import { User } from '../../../interfaces/User';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import { Pension, PensionIrrelevantReasons } from '../../../interfaces/Pension';
import Modules from '../../../utils/modules';
import { TaxReturn } from '../../../interfaces/taxReturn';
import { formatDate } from '../../../utils/formatDate';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const Details = () => {
    const { profileContextData, onEditRound, users } = useContext(UserProfileContext);
    const { client } = profileContextData;
    const [pension, setPension] = useState<any>(profileContextData.currentRound);

    useEffect(() => {
        setPension(profileContextData.currentRound);
    }, [profileContextData.currentRound]);

    const selectedModule = profileContextData.selectedModule;
    const allowedStatusesOptions = selectedModule && Object.values(selectedModule.allowedStatuses);
    const selectedModuleName = selectedModule?.data.moduleName;
    const repType = selectedModuleName === Modules.pension ? 'agent' : 'rep';

    const [repAgents, setRepAgents] = useState<User[]>(
        users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('pension')),
    );
    const [repOperators, setRepOperators] = useState<User[]>(users.filter((rep: User) => rep.roles.includes('operator')));
    const [showDateModal, setShowDateModal] = useState(false);
    const [mislakaSubmissionDate, setMislakaSubmissionDate] = useState<Date>(new Date());

    useEffect(() => {
        setRepAgents(users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('pension')));
        setRepOperators(users.filter((rep: User) => rep.roles.includes('operator')));
    }, [users]);

    const isPension = (object: any): object is Pension => {
        return 'agent' in object;
    };

    const hasOperator = (object: any): object is Pension | TaxReturn => {
        return 'operator' in object;
    };

    const handleDate = async () => {
        if (!pension) return;

        await onEditRound({ status: 'invalidMislaka', mislakaSubmissionDate }, selectedModuleName, pension._id, () =>
            setPension({
                ...pension,
                status: 'invalidMislaka',
                mislakaSubmissionDate,
            }),
        );
        setShowDateModal(false);
    };

    const onInvestmentRoundChecked = async (checked: boolean) => {
        if (!pension) return;

        await onEditRound({ isInvestmentRound: checked }, selectedModuleName, pension._id, () =>
            setPension({ ...pension, isInvestmentRound: checked }),
        );
    };

    const onSendFormsAutomaticallyChecked = async (checked: boolean) => {
        if (!pension) return;

        await onEditRound({ sendFormsAutomatically: checked }, selectedModuleName, pension._id, () =>
            setPension({ ...pension, sendFormsAutomatically: checked }),
        );
    };
    const onStatusSelected = async (status: string) => {
        if (!pension) return;

        if (status === 'invalidMislaka') {
            setShowDateModal(true);
            return;
        }

        await onEditRound({ status }, selectedModuleName, pension._id, () => setPension({ ...pension, status }));
    };

    const isValidPOA = () => {
        return true;
        // const poaDate = new Date(selectedModule.lastSignedPOADate);
        // const poaInvalidDate = new Date(poaDate.setMonth(poaDate.getMonth() + 3));
        // return poaInvalidDate > new Date((pension as Pension).mislaka?.submissionDate);
    };

    const evaluateAgentRepValueForSelect = () => {
        if (!pension) return '';
        let value;
        if (isPension(pension)) {
            value = pension.agent as string;
            const allAgentPossibleValues = [...repAgents.map((agent: User) => agent._id)];
            if (!allAgentPossibleValues.includes(value)) value = 'unknown';
        } else {
            value = pension.rep as string;
            const allRepPossibleValues = [...repAgents.map((rep: User) => rep._id), ...repOperators.map((rep: User) => rep._id)];
            if (!allRepPossibleValues.includes(value)) value = 'unknown';
        }
        return value;
    };

    const onAgentChanged = (userId: string) => {
        const prevRep = pension[repType];
        setPension({ ...pension, [repType]: userId });
        onEditRound({ [repType]: userId }, selectedModuleName, pension._id, undefined, () => setPension({ ...pension, [repType]: prevRep }));
    };

    const lastChangeDateString = pension?.lastStatusChangeDate
        ? `Status (Last change: ${formatDate(new Date(pension.lastStatusChangeDate))})`
        : 'Status';

    return (
        <div className="detailed-tab">
            <div>
                {client && pension ? (
                    <Row>
                        <Col>
                            <div className="select-area">
                                {allowedStatusesOptions && allowedStatusesOptions.length > 0 ? (
                                    <InputWrapper status={{ text: '' }} label={lastChangeDateString} wrapperClass="input-field select-field">
                                        <select
                                            value={pension.status || ''}
                                            className="form-select"
                                            name="status"
                                            onChange={(e: any) => onStatusSelected(e.target.value)}
                                        >
                                            <option disabled value={''}>
                                                Select status
                                            </option>

                                            {allowedStatusesOptions.map((option: any, index: number) => (
                                                <option key={index} value={option}>
                                                    {camelCaseToText(option)}
                                                </option>
                                            ))}
                                        </select>
                                    </InputWrapper>
                                ) : null}

                                {repAgents && repAgents.length > 0 ? (
                                    <InputWrapper status={{ text: '' }} label={'Agent'} wrapperClass="input-field select-field">
                                        <select
                                            value={evaluateAgentRepValueForSelect()}
                                            className="form-select"
                                            name="rep"
                                            onChange={(e: any) => onAgentChanged(e.target.value)}
                                        >
                                            {evaluateAgentRepValueForSelect() === 'unknown' ? (
                                                <option disabled value="unknown">
                                                    Unknown/inactive agent
                                                </option>
                                            ) : (
                                                <option disabled value="">
                                                    Select {repType}{' '}
                                                </option>
                                            )}

                                            {repAgents.map((option: any, index: number) => (
                                                <option key={index} value={option._id}>
                                                    {option.fullName}
                                                </option>
                                            ))}
                                        </select>
                                    </InputWrapper>
                                ) : (
                                    <div className="pension-status">No {repType} found.</div>
                                )}

                                {repOperators && repOperators.length > 0 && hasOperator(pension) ? (
                                    <InputWrapper status={{ text: '' }} label={'Operator'} wrapperClass="input-field select-field">
                                        <select
                                            value={pension.operator}
                                            className="form-select"
                                            name="operator"
                                            onChange={(e: any) => {
                                                onEditRound({ operator: e.target.value }, selectedModuleName, pension._id, () =>
                                                    setPension({
                                                        ...pension,
                                                        operator: e.target.value,
                                                    }),
                                                );
                                            }}
                                        >
                                            <option disabled value="">
                                                Select Operator
                                            </option>

                                            {repOperators.map((operator: User, index: number) => (
                                                <option key={index} value={operator._id}>
                                                    {operator.fullName}
                                                </option>
                                            ))}
                                        </select>
                                    </InputWrapper>
                                ) : (
                                    <div className="pension-status">No operators found.</div>
                                )}

                                <InputWrapper status={{ text: '' }} label={'Signing probability'} wrapperClass="input-field select-field">
                                    <select
                                        value={pension.signingProbability}
                                        className="form-select"
                                        name="signingProbability"
                                        onChange={(e: any) => {
                                            onEditRound({ signingProbability: e.target.value }, selectedModuleName, pension._id, () =>
                                                setPension({
                                                    ...pension,
                                                    signingProbability: e.target.value,
                                                }),
                                            );
                                        }}
                                    >
                                        <option value="" selected={true}>
                                            Please choose
                                        </option>
                                        <option value="high">High</option>
                                        <option value="medium">Medium</option>
                                        <option value="low">Low</option>
                                    </select>
                                </InputWrapper>

                                <InputWrapper>
                                    <br />
                                    <Form.Check
                                        type="checkbox"
                                        label="Send forms to insurance company automatically"
                                        checked={pension.sendFormsAutomatically}
                                        onChange={(e) => onSendFormsAutomaticallyChecked(e.target.checked)}
                                    />
                                </InputWrapper>

                                {pension.status === 'irrelevant' ? (
                                    <InputWrapper status={{ text: '' }} label={'Irrelevant Reason'} wrapperClass="input-field select-field">
                                        <select
                                            value={(pension as Pension).irrelevantReason}
                                            className="form-select"
                                            name="irrelevantReason"
                                            onChange={(e: any) => {
                                                onEditRound({ irrelevantReason: e.target.value }, selectedModuleName, pension._id, () =>
                                                    setPension({
                                                        ...pension,
                                                        irrelevantReason: e.target.value,
                                                    }),
                                                );
                                            }}
                                        >
                                            <option value="" selected={true}>
                                                Please choose
                                            </option>
                                            {Object.values(PensionIrrelevantReasons).map((reason: string, index: number) => (
                                                <option key={index} value={reason}>
                                                    {camelCaseToText(reason)}
                                                </option>
                                            ))}
                                        </select>
                                    </InputWrapper>
                                ) : null}

                                <div>
                                    <b>Mislaka submission date: </b>
                                    <span>{formatDate((pension as Pension).mislaka?.submissionDate, true)}</span>
                                </div>
                                {!isValidPOA() ? (
                                    <Alert variant="danger" className="my-2">
                                        POA not valid on submission date
                                    </Alert>
                                ) : null}

                                <div>
                                    <b>Agent meeting date: </b>
                                    <span>{formatDate((pension as Pension).agentMeetingTime, true)}</span>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <Form.Check
                                type="checkbox"
                                label="Investment round"
                                checked={pension.isInvestmentRound}
                                onChange={(e) => onInvestmentRoundChecked(e.target.checked)}
                            />
                        </Col>
                    </Row>
                ) : (
                    <div>Sorry, no data yet.</div>
                )}
            </div>

            <Modal className="date-modal" backdropClassName="date-backdrop" show={showDateModal} onHide={() => setShowDateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{'Select Mislaka Submission Date'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DatePicker
                        name="mislakaSubmissionDate"
                        className={'date-input'}
                        selected={mislakaSubmissionDate}
                        onChange={(date: Date) => setMislakaSubmissionDate(date)}
                        dateFormat={'dd/MM/yyyy'}
                        placeholderText={'select date'}
                        customInput={<input className="btn btn-light date-input" />}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDateModal(false)}>
                        {'Cancel'}
                    </Button>
                    <Button variant="success" onClick={handleDate}>
                        {'Done'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Details;
